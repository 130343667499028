/* eslint-disable */
let canvasElement;
let context;
const fps = 60;
let fpsTimmer = 0;
let fadeTime = 0;
let matrixSize = [18, 10];
let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
let screenSize = [vw, vh];
let enableAnimation = true;
let changePatternInterval = null;
const lightPatterns = [
  lightPattern1,
  lightPattern2,
  lightPattern3,
  strobe1,
  strobe2,
];

let currenLightPattern = 0;
let matrix = null;
let colors = [
  [246, 57, 237], //purple
  [68, 245, 243], //teal
]

function randomnumber(max, min) {
  if (typeof min === 'undefined') {
    min = 0;
  }

  return Math.floor(Math.random() * max) + min;
}

function init(el) {
  enableAnimation = true;

  canvasElement = el;
  context = canvasElement.getContext('2d');

  //build matrix
  matrix = new Array(matrixSize[0]); // create an empty array of length n
  for (let y = 0; y < matrixSize[1]; y++) {
    matrix[y] = new Array(matrixSize[1]); // make each element an array
    for (let x = 0; x < matrixSize[0]; x++) {
      matrix[y][x] = [0, 0, 0];
    }
  }

  window.addEventListener('resize', screenResize);
  screenResize();

  // Loop
  window.requestAnimationFrame(loop);

  changePatternInterval = setInterval(function () {
    let random_number = randomnumber(lightPatterns.length);
    while (lightPatterns.length > 1 && currenLightPattern === random_number) {
      random_number = randomnumber(lightPatterns.length);
    }

    console.log(`lightPatterns: ${random_number}`);

    // let random_number = randomnumber(3);
    currenLightPattern = random_number;
  }, 2000);
}

function destroy() {
  clearInterval(changePatternInterval);
  window.removeEventListener('resize', screenResize);
  enableAnimation = false;
}

function loop() {
  let now = Date.now();
  if (now >= fpsTimmer) {
    lightPatterns[currenLightPattern]();
    render();
    now = Date.now();
    fpsTimmer = now + 1000 / fps;
  }

  if (enableAnimation) {
    requestAnimationFrame(loop);
  } else {
    context = null;
    matrix = null;
  }
}

function render() {
  let pixelWidth = screenSize[0] / matrixSize[0];
  let pixelHeight = screenSize[1] / matrixSize[1];

  for (let y = 0; y < matrixSize[1]; y++) {
    for (let x = 0; x < matrixSize[0]; x++) {
      let color = matrix[y][x];
      if (color instanceof Array) {
        context.fillStyle = 'rgb(' + color[0] + ', ' + color[1] + ', ' + color[2] + ')';
        context.fillRect(x, y, x + 1, y + 1);
        //console.log(pixelWidth * x, pixelWidth * y, pixelWidth * (x+1), pixelWidth * (y+1));
      }
    }
  }
}

function resize() {
  context.canvas.width = matrixSize[0];
  context.canvas.height = matrixSize[1];
}

function lightPattern1() {
  for (let y = 0; y < matrixSize[1]; y++) {
    for (let x = 0; x < matrixSize[0]; x++) {
      let tigger = randomnumber(18) === 0;
      // New pixel
      if (tigger) {
        let colorIndex = randomnumber(colors.length);
        matrix[y][x] = [
          colors[colorIndex][0],
          colors[colorIndex][1],
          colors[colorIndex][2]
        ];
      } else {
        matrix[y][x][0] = Math.floor(matrix[y][x][0] * 0.85);
        matrix[y][x][1] = Math.floor(matrix[y][x][1] * 0.85);
        matrix[y][x][2] = Math.floor(matrix[y][x][2] * 0.85);
      }
    }
  }
}

let lightPattern2lastIndex = 0;
let lightPattern2Index = 0;
let lightPattern2IndexSpacing = matrixSize[1] + 10;

function lightPattern2() {
  for (let y = 0; y < matrixSize[1]; y++) {
    for (let x = 0; x < matrixSize[0]; x++) {
      let tigger = ((lightPattern2Index + x + y * matrixSize[1]) % lightPattern2IndexSpacing) === 0;
      if (tigger) {
        lightPattern2lastIndex++
        let colorIndex = Math.floor(lightPattern2lastIndex / ((matrixSize[0] * matrixSize[1]))) % colors.length;
        matrix[y][x] = [
          colors[colorIndex][0],
          colors[colorIndex][1],
          colors[colorIndex][2]
        ];
      } else {
        matrix[y][x][0] = Math.floor(matrix[y][x][0] * 0.85);
        matrix[y][x][1] = Math.floor(matrix[y][x][1] * 0.85);
        matrix[y][x][2] = Math.floor(matrix[y][x][2] * 0.85);
      }
    }
  }

  lightPattern2Index++;
}

function lightPattern3() {

  // Sprites
  const sprites = [
    [
      [0, 1, 1, 0, 0, 1, 1, 0],
      [1, 1, 1, 1, 1, 0, 0, 1],
      [1, 1, 1, 1, 0, 0, 0, 1],
      [1, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 0, 0],
      [0, 0, 0, 1, 1, 0, 0, 0],
    ], [
      [1, 0, 0, 0, 1, 0, 0, 0],
      [1, 1, 1, 1, 1, 0, 1, 0],
      [1, 1, 1, 1, 1, 0, 0, 1],
      [0, 1, 1, 0, 1, 0, 0, 1],
      [1, 0, 1, 1, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 1, 0],
      [0, 1, 0, 1, 0, 0, 1, 0],
    ], [
      [1, 0, 0, 0, 0, 0, 0, 1],
      [0, 1, 0, 0, 0, 0, 1, 0],
      [0, 0, 1, 1, 1, 1, 0, 0],
      [0, 1, 0, 1, 1, 0, 1, 0],
      [0, 0, 1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1],
      [1, 0, 1, 0, 0, 1, 0, 1],
      [1, 0, 1, 1, 1, 1, 0, 1],
    ], [
      [0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0, 0],
      [0, 0, 1, 0, 1, 0, 0, 0],
      [0, 1, 0, 1, 0, 1, 0, 0],
      [1, 0, 1, 0, 1, 0, 1, 0],
      [0, 1, 1, 1, 1, 1, 0, 0],
      [0, 0, 1, 0, 1, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0, 0],
    ], [
      [0, 0, 0, 1, 1, 1, 0, 0],
      [0, 0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 0, 0, 1, 0, 0, 1],
      [0, 1, 1, 0, 1, 0, 1, 1],
      [0, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 0, 1, 0, 1, 0, 1],
    ],
  ];

  for (let y = 0; y < matrixSize[1]; y++) {
    for (let x = 0; x < matrixSize[0]; x++) {
      matrix[y][x][0] = Math.floor(matrix[y][x][0] * 0.85);
      matrix[y][x][1] = Math.floor(matrix[y][x][1] * 0.85);
      matrix[y][x][2] = Math.floor(matrix[y][x][2] * 0.85);
    }
  }

  // print sprite
  const skip = 10 * Math.random() > 2;
  const sprite = Math.floor(sprites.length * Math.random());
  const startX = Math.floor(matrixSize[0] * Math.random() - 4);
  const startY = Math.floor(matrixSize[1] * Math.random() - 4);
  const colorIndex = randomnumber(colors.length);
  const flip = Math.round(Math.random()) === 1;

  if (!skip){
    for (let y = 0; y < 8; y++) {
      for (let x = 0; x < 8; x++) {
        const currentX = startX + x;
        const currentY = startY + y;
  
        if (currentX >= 0 && currentY >= 0 && currentX < matrixSize[0] && currentY < matrixSize[1]) {
          if (flip){
            if (sprites[sprite][y][7 - x] > 0) {
              matrix[currentY][currentX][0] = colors[colorIndex][0] * sprites[sprite][y][7 - x];
              matrix[currentY][currentX][1] = colors[colorIndex][1] * sprites[sprite][y][7 - x];
              matrix[currentY][currentX][2] = colors[colorIndex][2] * sprites[sprite][y][7 - x];
            }
          } else {
            if (sprites[sprite][y][x] > 0) {
              matrix[currentY][currentX][0] = colors[colorIndex][0] * sprites[sprite][y][x];
              matrix[currentY][currentX][1] = colors[colorIndex][1] * sprites[sprite][y][x];
              matrix[currentY][currentX][2] = colors[colorIndex][2] * sprites[sprite][y][x];
            }
          }
        }
      }
    }
  }
}

function strobe1() {
  let colorIndex = randomnumber(colors.length);
  let tigger = randomnumber(5) === 0;

  for (let y = 0; y < matrixSize[1]; y++) {
    for (let x = 0; x < matrixSize[0]; x++) {
      if (tigger) {
        matrix[y][x] = [
          colors[colorIndex][0],
          colors[colorIndex][1],
          colors[colorIndex][2]
        ];
      } else {
        matrix[y][x][0] = Math.floor(matrix[y][x][0] * 0.6);
        matrix[y][x][1] = Math.floor(matrix[y][x][1] * 0.6);
        matrix[y][x][2] = Math.floor(matrix[y][x][2] * 0.6);
      }
    }
  }
}

function strobe2() {
  let colorIndex = randomnumber(colors.length);
  let tigger = randomnumber(8) === 0;
  let horizontal = randomnumber(2);
  let isHorizontal = horizontal === 1;
  let divider = randomnumber(8) + 1;
  let slice = Math.floor(matrixSize[horizontal] / divider);
  let start = randomnumber(divider) * slice;
  let end = start + slice;

  for (let y = 0; y < matrixSize[1]; y++) {
    for (let x = 0; x < matrixSize[0]; x++) {
      if (isHorizontal && tigger && x >= start && x <= end) {
        matrix[y][x] = [
          colors[colorIndex][0],
          colors[colorIndex][1],
          colors[colorIndex][2]
        ];
      } else if (!isHorizontal && tigger && y >= start && y <= end) {
        matrix[y][x] = [
          colors[colorIndex][0],
          colors[colorIndex][1],
          colors[colorIndex][2]
        ];
      } else {
        matrix[y][x][0] = Math.floor(matrix[y][x][0] * 0.8);
        matrix[y][x][1] = Math.floor(matrix[y][x][1] * 0.8);
        matrix[y][x][2] = Math.floor(matrix[y][x][2] * 0.8);
      }
    }
  }
}

function screenResize() {
  let matrixSizeWidth,
    matrixSizeHeight;

  vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  screenSize = [vw, vh];

  if (vw > vh) {
    if (vw > 1000) {
      matrixSizeWidth = Math.round(vw / 80);
      matrixSizeHeight = Math.round(vh / 80);
    } else {
      matrixSizeWidth = Math.round(vw / 40);
      matrixSizeHeight = Math.round(vh / 40);
    }
  } else {
    if (vh > 1000) {
      matrixSizeWidth = Math.round(vw / 80);
      matrixSizeHeight = Math.round(vh / 80);
    } else {
      matrixSizeWidth = Math.round(vw / 40);
      matrixSizeHeight = Math.round(vh / 40);
    }
  }

  matrixSize = [matrixSizeWidth, matrixSizeHeight];

  //rebuild matrix
  for (let y = 0; y < matrixSize[1]; y++) {
    if (!Array.isArray(matrix[y])) {
      matrix[y] = new Array(matrixSize[1]);
      for (let x = 0; x < matrixSize[0]; x++) {
        matrix[y][x] = [0, 0, 0];
      }
    } else {
      for (let x = 0; x < matrixSize[0]; x++) {
        if (!Array.isArray(matrix[y][x])) {
          matrix[y][x] = [0, 0, 0];
        }
      }
    }
  }

  resize();
  console.log('resize');
}

export default {
  init: init,
  destroy: destroy
};